import {
	computed,
	type ComputedRef,
	nextTick,
	onBeforeMount,
	type Ref,
	type ShallowRef,
	shallowRef,
	toRaw,
	watch
} from 'vue';
import type {LocationQueryValue} from 'vue-router';
import {useRouter} from 'vue-router';
import FilterWorker from '../lib/filter/filterWorker?worker&inline';
import {debounce} from '~/utils/debounce';
import type {FilterOptions, FilterWorkerMessage} from '~/lib/filter/filterTypes';
import {filterItem} from '~/lib/filter/filter';

export const useFilter = <T extends Record<string, unknown>>(
	items: Ref<Array<T>>,
	options: Ref<FilterOptions<T>>,
	filterType: 'worker' | 'no-worker' = 'worker'
) => {
	const router = useRouter();

	const normalizedSearchTerm = computed(() => options.value.search.value.toLowerCase().trim());
	const appliedFilters = computed(() => options.value.filters.map(filter => {
		const text = filter.type === 'select' ? filter.value : filter.values.join();
		return `${filter.label}:${text}`;
	}).join(' '));

	const worker = new FilterWorker();
	const updateFilteredItems = (updateItems = false) => {
		if (filterType === 'no-worker') {
			return;
		}

		const message: FilterWorkerMessage<T> = {
			items: updateItems ? toRaw(items.value.map(item => toRaw(item))) : undefined,
			options: toRaw(options.value)
		};

		worker.postMessage(message);
	};

	let filteredItems: ShallowRef<Array<T>> | ComputedRef<Array<T>>;

	if (filterType === 'worker') {
		filteredItems = shallowRef<Array<T>>([]);

		worker.onmessage = (e) => {
			nextTick().then(() => {
				(filteredItems as ShallowRef<Array<T>>).value = e.data.items;
			});
		};
	} else {
		filteredItems = computed(() => {
			const normalizedOptions = {
				...options.value,
				search: {
					...options.value.search,
					value: normalizedSearchTerm.value.toLowerCase().trim()
				}
			};
			return items.value.filter((item) => filterItem<T>(item, normalizedOptions));
		});
	}

	const getQueryWithoutSearch = () => {
		const query = router.currentRoute.value.query;
		const newQuery: Record<string, LocationQueryValue | LocationQueryValue[]> = {};

		for (const [key, value] of Object.entries(query)) {
			if (key !== 'search') {
				newQuery[key] = value;
			}
		}

		return newQuery;
	};

	const getQueryWithoutFilter = () => {
		const query = router.currentRoute.value.query;
		const newQuery: Record<string, LocationQueryValue | LocationQueryValue[]> = {};

		for (const [key, value] of Object.entries(query)) {
			if (!key.startsWith('filter[')) {
				newQuery[key] = value;
			}
		}

		return newQuery;
	};

	const debouncedUpdateFilteredItems = debounce(updateFilteredItems, 200);

	watch(items, () => {
		updateFilteredItems(true);
	}, {
		immediate: true
	});

	const resetFilters = () => {
		options.value.search.value = '';
		options.value.filters.forEach(filter => {
			if (filter.type === 'checkbox') {
				filter.values = [];
			}
			if (filter.type === 'select') {
				filter.value = '';
			}
		});
	};

	const getQueryForCurrentFilters = () => {
		const queryOptions: Record<`filter[${string}]`, string> = {};

		options.value.filters.forEach(filter => {
			if (filter.type === 'checkbox' && filter.values.length > 0) {
				queryOptions[`filter[${String(filter.key)}]`] = filter.values.join(',');
			}

			if (filter.type === 'select' && filter.value.length > 0) {
				queryOptions[`filter[${String(filter.key)}]`] = filter.value;
			}
		});

		return queryOptions;
	};

	watch(appliedFilters, () => {
		updateFilteredItems();

		if (appliedFilters.value === '') {
			router.replace({
				query: {
					...getQueryWithoutFilter()
				}
			});
			return;
		}

		router.replace({
			query: {
				...getQueryWithoutFilter(),
				...getQueryForCurrentFilters()
			}
		});
	});

	watch(normalizedSearchTerm, (searchValue) => {
		debouncedUpdateFilteredItems();
		if (searchValue === '') {
			router.replace({
				query: {
					...getQueryWithoutSearch()
				}
			});
			return;
		}

		router.replace({
			query: {
				...getQueryWithoutSearch(),
				search: searchValue
			}
		});
	});

	onBeforeMount(() => {
		resetFilters();

		const query = router.currentRoute.value.query;
		options.value.search.value = String(query?.search ?? '');

		Object.entries(query).forEach(([key, value]) => {
			if (key.startsWith('filter[')) {
				const filterKey = key.replace('filter[', '').replace(']', '');
				const filter = options.value.filters.find(filter => filter.key === filterKey);

				if (!filter) {
					return;
				}

				if (filter.type === 'checkbox') {
					filter.values = String(value).split(',');
				}
				if (filter.type === 'select') {
					filter.value = String(value);
				}
			}
		});

	});

	return {
		filteredItems,
		resetFilters
	};
};
