import type {FilterOptions} from '~/lib/filter/filterTypes';

type MetadataValueEntry = { key: string, value: string };

export const filterItem = <T extends Record<string, unknown>>(item: T, filterOptions: FilterOptions<T>): boolean => {
	const normalizedSearchTerm = filterOptions.search.value.toLowerCase().trim();
	const keys = filterOptions.search.keys;
	const filtersSelected = filterOptions.filters.some((filter) => {
		return (filter.type === 'select' && filter.value.length > 0) ||
			(filter.type === 'checkbox' && filter.values.length > 0);
	});
	if (normalizedSearchTerm === '' && !filtersSelected) {
		return true;
	}

	if (filtersSelected) {
		const filterResults = filterOptions.filters.map((filter) => {
			if (filter.type === 'checkbox' && filter.values.length > 0) {
				return filter.values?.includes(String(item[filter.key]));
			}
			if (filter.type === 'select' && filter.value.length > 0) {
				return filter.value === String(item[filter.key]);
			}

			return true;
		});
		if (filterResults.includes(false)) {
			return false;
		}
	}

	if (!keys || keys.length === 0) {
		const values = Object.values(item).join(' ').toLowerCase();
		return values.includes(normalizedSearchTerm);
	}

	return keys.some((key) => {
		if (typeof key !== 'string') {
			return;
		}
		if (key.startsWith('metadata.')) {
			const metadataKey = key.split('.')[1];
			const metadataValue = (item.metadata as MetadataValueEntry[])
				.find((entry) => entry.key === metadataKey);
			if (!metadataValue) {
				return false;
			}

			return metadataValue.value?.toLowerCase().includes(normalizedSearchTerm);
		}
		const value = String(item[key]).toLowerCase();
		return value.includes(normalizedSearchTerm);
	});
};
